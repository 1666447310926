// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export function blobDownload(blob: Blob, filename: string) {
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}