// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductSettingService {

  itemsToOrder: object = {}
  itemsToOrderDisplay: object = {}
  labelUrl:string

  constructor() { }
  setItems(item: any) {
    this.itemsToOrder[item.catalog_object_id]={catalog_object_id:item.catalog_object_id,quantity:item.quantity,metadata:item.metadata, name:item.name, variationName:item.size, isPrescribed:item.isPrescribed}
    this.itemsToOrderDisplay[item.catalog_object_id]=item
  }
  get items() {
    return Object.values(this.itemsToOrder)
  }
  get itemsToDisplay() {
    return Object.values(this.itemsToOrderDisplay)
  }

  deleteItem(id:any){
    delete this.itemsToOrder[id]
    delete this.itemsToOrderDisplay[id]

  }
  get getLabelUrl() {
    return this.labelUrl
  }

  clearItems(){
    this.itemsToOrder={}
    this.itemsToOrderDisplay = {}
  }

  setLabelUrl(url:string) {
    this.labelUrl=url
  }

  get orderStatus() {
    return Number(localStorage.getItem('orderStatus'))
  }

  setOrderStatus(status) {
    localStorage.setItem('orderStatus',(status))
  }
  get billingAddressId() {
    return Number(localStorage.getItem('billingAddressId'))
  }
  setUserBillingAddressId(id){
    localStorage.setItem('billingAddressId',(id))
  }

  

}

