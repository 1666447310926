// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export function therapyScheduleOptions(): Object[] {
    return [
        { value: 0, viewValue: 'No schedule' },
        { value: 1, viewValue: '1 day per week' },
        { value: 2, viewValue: '2 days per week' },
        { value: 3, viewValue: '3 days per week' },
        { value: 4, viewValue: '4 days per week' },
        { value: 5, viewValue: '5 days per week' },
        { value: 6, viewValue: '6 days per week' },
        { value: 7, viewValue: '7 days per week' },
        { value: 14, viewValue: 'Once every 2 weeks' },
        { value: 28, viewValue: 'Once every 4 weeks' },
    ];
}