// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { Clinician, ClinicianDetails, ClinicianUpdate } from 'src/app/shared/models/clinician';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClinicianService {
  constructor(
    private readonly http: HttpClient,
    private readonly appSettingsService: AppSettingsService
  ) {}

  public read(id: number): Observable<ClinicianDetails> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<ClinicianDetails>(`${appSettings.apiBaseUrl}/v1.0/clinic/clinicians/${id}/details`))
    );
  }

  public readByUserId(userId: number): Observable<ClinicianDetails> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<ClinicianDetails>(`${appSettings.apiBaseUrl}/v1.0/clinic/clinicians/${userId}/detailsbyuserid`))
    );
  }

  public update(id: number, model: ClinicianUpdate): Observable<Clinician> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<Clinician>(`${appSettings.apiBaseUrl}/v1.0/clinic/clinicians/${id}`, model))
    );
  }
}
