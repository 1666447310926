// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export class DatabaseRecord {
    public id: number;
    public timestamp: Date;
    public modified?: Date;
}

export class DatabaseRecordGuid extends DatabaseRecord {
    public guid: string;
}