// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { APIQuery } from 'src/app/shared/models/api-query.interface';
import { JournalEvent } from 'src/app/shared/models/journal-event';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';

@Injectable({
  providedIn: 'root'
})
export class JournalEventsService {

  constructor(private readonly http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  public query(apiQuery: APIQuery, userId: number) {
    let params: any = { deleted: false };

    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!userId) {
      params.userId = userId;
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<JournalEvent>>(`${appSettings.apiBaseUrl}/v1.0/journalevents/query/paged`, { params }))
    );
  }

  public querySummary(apiQuery: APIQuery, userId: number, groupByDay?: boolean, start?: Date, end?: Date)
  {
    let params: any = {};

    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!userId) {
      params.userId = userId;
    }
    if (!!groupByDay) {
      params.groupByDay = groupByDay
    }
    if (!!start) {
      params.start = start.toISOString();
    }
    if (!!end) {
      params.end = end.toISOString();
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<JournalEvent>>(`${appSettings.apiBaseUrl}/v1.0/journalevents/query/summary`, { params }))
    );
  }

  public querySummaryDetails(apiQuery: APIQuery, userId: number, start?: Date, end?: Date)
  {
    let params: any = {};

    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!userId) {
      params.userId = userId;
    }
    if (!!start) {
      params.start = start.toISOString();
    }
    if (!!end) {
      params.end = end.toISOString();
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<JournalEvent>>(`${appSettings.apiBaseUrl}/v1.0/journalevents/query/summaryDetails`, { params }))
    );
  }

  public queryTable(apiQuery: APIQuery, userId: number, groupByDay?: boolean, start?: Date, end?: Date)
  {
    let params: any = {};

    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!userId) {
      params.userId = userId;
    }
    if (!!groupByDay) {
      params.groupByDay = groupByDay
    }
    if (!!start) {
      params.start = start.toISOString();
    }
    if (!!end) {
      params.end = end.toISOString();
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<JournalEvent>>(`${appSettings.apiBaseUrl}/v1.0/journalevents/query/table`, { params }))
    );
  }

  public export(apiQuery?: APIQuery, userId?: number): Observable<Blob> {
    let params = { };
    
    if(userId) {
      params['userId'] = userId.toString();
    }
    
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/journalevents/export`, { params, responseType: 'blob' }))
    );
  }

  public showScreeningCriteriaMet(userId: number): Observable<boolean> {
    let params: any = {};

    if (!!userId) {
      params.userId = userId;
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<boolean>(`${appSettings.apiBaseUrl}/v1.0/journalevents/showScreeningCriteriaMet`, { params }))
    );
  }

  public screeningCriteriaMet(userId: number): Observable<boolean> {
    let params: any = {};

    if (!!userId) {
      params.userId = userId;
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<boolean>(`${appSettings.apiBaseUrl}/v1.0/journalevents/screeningCriteriaMet`, { params }))
    );
  }
}
