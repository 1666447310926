// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { ClinicianForm, FacilityForm, JotQuery, JotUpdate } from 'src/app/shared/models/jot-form.interface';

@Injectable({
  providedIn: 'root'
})
export class JotService {

  constructor(private readonly http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  //Facility Form
  public queryFacilities(query?: JotQuery): Observable<FacilityForm[]> {
    let params = Object.entries(query).reduce((a, [k,v]) => (v == null ? a : (a[k]=v, a)), {})
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<FacilityForm[]>(`${appSettings.apiBaseUrl}/v1/jot/facilities`, {params}))
    );
  }

  public readFacility(id: string): Observable<FacilityForm> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<FacilityForm>(`${appSettings.apiBaseUrl}/v1/jot/facilities/${id}`))
    );
  }

  public updateFacility(id: string, model: JotUpdate): Observable<number> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<number>(`${appSettings.apiBaseUrl}/v1/jot/facilities/${id}`, model))
    );
  }

  // Clinician Form
  public queryClinicians(query?: JotQuery): Observable<ClinicianForm[]> {
    let params = Object.entries(query).reduce((a, [k,v]) => (v == null ? a : (a[k]=v, a)), {})

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<ClinicianForm[]>(`${appSettings.apiBaseUrl}/v1/jot/clinicians`, {params}))
    );
  }

  public readClinician(id: string): Observable<ClinicianForm> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<ClinicianForm>(`${appSettings.apiBaseUrl}/v1/jot/clinicians/${id}`))
    );
  }

  public updateClinician(id: string, model: JotUpdate): Observable<number> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<number>(`${appSettings.apiBaseUrl}/v1/jot/clinicians/${id}`, model))
    );
  }
}
